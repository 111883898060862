import { useGraphQL } from '@/data';
import { queryGraphQL } from '@/data/apollo';
import { GooglePlaceDetails, GooglePlaces } from '@/data/googlePlaces.graphql';
import { Place, QueryGetAutoCompletePlacesArgs, QueryGetPlaceDetailsArgs } from '@/types/schema';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { Autocomplete, AutocompleteProps, Box, Grid, TextField, Typography } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { useField } from 'formik';
import { intersection } from 'lodash-es';
import { useDebouncedValue } from 'rooks';
import { FormattedTextFieldProps } from '../../formattedTextField';
import FormTextField from './textField';
import { useState } from 'react';

export type GooglePlace = {
	line1?: string,
	city?: string,
	state?: string,
	country?: string,
	postalCode?: string,
	lat?: string | number,
	lng?: string | number
};
type OnSelectFunction = ( place: GooglePlace ) => void;

// This function formats Google Place data into a custom GooglePlace type
function getPlace( place: Place ): GooglePlace {
	
	const temp: Record<string, string> = {};
	const location: GooglePlace = {
		lat: +place.geometry.location.lat,
		lng: +place.geometry.location.lng,
	};
	place.addressComponents.forEach( ( addressComponent ) => {
		if ( intersection( addressComponent.types, [ 'street_number' ] ).length ) {
			temp.line1a = addressComponent.longName;
		} else if ( intersection( addressComponent.types, [ 'route' ] ).length ) {
			temp.line1b = addressComponent.longName;
		} else if ( intersection( addressComponent.types, [ 'locality' ] ).length ) {
			location.city = addressComponent.longName;
		} else if ( intersection( addressComponent.types, [ 'administrative_area_level_1' ] ).length ) {
			location.state = addressComponent.shortName;
		} else if ( intersection( addressComponent.types, [ 'country' ] ).length ) {
			location.country = addressComponent.longName;
		} else if ( intersection( addressComponent.types, [ 'postal_code' ] ).length ) {
			location.postalCode = addressComponent.longName;
		}
	} );
	location.line1 = [ temp.line1a, temp.line1b ].filter( Boolean ).join( ' ' );
	return location;
}

interface SimpleGooglePlacesSearchProps {
	value: string
	setValue: ( value: string ) => void
	onSelect: ( address: GooglePlace ) => void
	placeholder?: string
}

/**
 * @description This simple search Google Places component doesn't use formik as the other one does
 */
export function SimpleGooglePlacesSearch( {
	value,
	setValue,
	placeholder,
	onSelect,
}: SimpleGooglePlacesSearchProps ) {
	const [ delayedInputValue ] = useDebouncedValue( value, 800 );
	
	const { data, isFetching } = useGraphQL<QueryGetAutoCompletePlacesArgs>( {
		queryKey : [ 'googlePlaces' ],
		query    : GooglePlaces,
		variables: { input: delayedInputValue },
	}, { enabled: Boolean( delayedInputValue ) } );

	return <Autocomplete
	filterSelectedOptions
	disableClearable
	autoComplete
	includeInputInList
	isOptionEqualToValue={( option: Place, value: string ) => option.line1 === value}
	id='google-places-search'
	options={data?.getAutoCompletePlaces ?? []}
	loading={isFetching}
	value={value || ''}
	filterOptions={( x ) => x}
	loadingText='Searching...'
	noOptionsText='No locations'
	getOptionLabel={( option: Place | null ) => typeof option === 'string' ? option : option.line1}
	renderInput={( params ) => (
		<TextField
			{...params}
			placeholder={placeholder || 'Search for a location'}
			onChange={( e ) => setValue( e.target.value )}
		/>
	)}
	renderOption={( props, place: Place ) => {
		const matches = place.matches || [];
		const parts = parse(
			place.line1,
			matches.map( ( match: any ) => [ match.offset, match.offset + match.length ] ),
		);
		
		return (
			<li {...props} key={place.id ?? ''}>
				<Grid container alignItems='center'>
					<Grid item sx={{ display: 'flex', width: 44 }}>
						<LocationOnIcon sx={{ color: 'text.secondary' }}/>
					</Grid>
					<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
						{parts?.map( ( part, index ) => (
							<Box
								key={index}
								component='span'
								sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
								{part.text}
							</Box>
						) )}
						<Typography variant='body2' color='text.secondary'>
							{place.secondaryText}
						</Typography>
					</Grid>
				</Grid>
			</li>
		);
	}}
	onChange={async ( event, value: Place ) => {
		if ( !value ) return;
		
		if ( value?.id ) {
			const { getPlaceDetails } = await queryGraphQL<QueryGetPlaceDetailsArgs>( {
				query    : GooglePlaceDetails,
				variables: { placeId: value.id },
			} );
			
			const location = getPlace( getPlaceDetails );
			onSelect( location );
			setValue( value.line1 );

		}
	}}
	/>;
}

export default function GooglePlacesSearch( {
	onSelect,
	textFieldProps,
	name,
	...props
}: {
	onSelect?: OnSelectFunction,
	textFieldProps?: FormattedTextFieldProps,
	name: string
} & Partial<AutocompleteProps<any, any, any, any>> ) {
	
	const [ field, {}, { setValue } ] = useField( name );
	
	const [ delayedInputValue ] = useDebouncedValue( field.value, 800 );
	
	const { data, isFetching } = useGraphQL<QueryGetAutoCompletePlacesArgs>( {
		queryKey : [ 'googlePlaces' ],
		query    : GooglePlaces,
		variables: { input: delayedInputValue },
	}, { enabled: Boolean( delayedInputValue ) } );
	
	return (
		<Autocomplete
			filterSelectedOptions
			disableClearable
			autoComplete
			includeInputInList
			isOptionEqualToValue={( option: Place, value: string ) => option.line1 === value}
			id='google-places-search'
			options={data?.getAutoCompletePlaces ?? []}
			loading={isFetching}
			value={field.value || ''}
			filterOptions={( x ) => x}
			loadingText='Searching...'
			noOptionsText='No locations'
			getOptionLabel={( option: Place | null ) => typeof option === 'string' ? option : option.line1}
			{...props}
			renderOption={( props, place: Place ) => {
				const matches = place.matches || [];
				const parts = parse(
					place.line1,
					matches.map( ( match: any ) => [ match.offset, match.offset + match.length ] ),
				);
				
				return (
					<li {...props} key={place.id ?? ''}>
						<Grid container alignItems='center'>
							<Grid item sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }}/>
							</Grid>
							<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								{parts?.map( ( part, index ) => (
									<Box
										key={index}
										component='span'
										sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
										{part.text}
									</Box>
								) )}
								<Typography variant='body2' color='text.secondary'>
									{place.secondaryText}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
			renderInput={( params ) => (
				<FormTextField
					{...params}
					{...textFieldProps}
					name={name}
				/>
			)}
			onChange={async ( event, value: Place ) => {
				if ( !value ) return;
				
				if ( value?.id ) {
					const { getPlaceDetails } = await queryGraphQL<QueryGetPlaceDetailsArgs>( {
						query    : GooglePlaceDetails,
						variables: { placeId: value.id },
					} );
					
					const location = getPlace( getPlaceDetails );
					onSelect( location );
					setValue( value.line1 );
				}
			}}
		/>
	);
}
